<template>
    <b-sidebar
     id="navigation-form-sidebar"
     :visible="isNavigationFormSidebarActive"
     bg-variant="white"
     sidebar-class="sidebar-lg"
     shadow
     backdrop
     no-header
     right
     @shown="getData(navigationData)"
     @hidden="resetForm"
     @change="(val) => $emit('update:is-navigation-form-sidebar-active', val)"
   >

   <template #default="{ hide }"> 
       <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
           <h5 class="mb-0">
               Navigation  Form
           </h5>
   
           <feather-icon
               class="ml-1 cursor-pointer"
               icon="XIcon"
               size="16"
               @click="hide"
           />
       </div>
       <validation-observer
         #default="{ handleSubmit }"
         ref="refFormObserver"
       >
         <b-form
           class="p-2"
           @submit.prevent="handleSubmit(onSubmit)"
           @reset.prevent="resetForm"
         >
           
                    <validation-provider
             #default="validationContext"
             name="Title"
             rules="required"
           >
           
             <b-form-group
               label="Title"
               label-for="title"
             >
               <b-form-input
                 id="title"
                 v-model="Data.title"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="Title"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
           <validation-provider
             #default="validationContext"
             name="uri"
             rules="required"
           >
             <b-form-group
               label="Uri"
               label-for="uri"
             >
               <b-form-input
                 id="uri"
                 v-model="Data.uri"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="uri"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
           <validation-provider
             #default="validationContext"
             name="icon"
             rules="min:0"
           >
             <b-form-group
               label="Icon"
               label-for="icon"
             >
               <b-form-input
                 id="icon"
                 v-model="Data.icon"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="Icon"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
          
           
           <validation-provider
             #default="validationContext"
             name="IsActive"
             rules="min:0"
           >
             <b-form-group
               label="Is Active"
               label-for="IsActive"
             >
             
               <b-form-checkbox
               id="isactive"
                               checked="true"
                               class="custom-control-success"
                               v-model="Data.is_active"
                               switch
                 />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
         
                


           <div class="d-flex mt-2">
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="primary"
               class="mr-2"
               type="submit"
             >
               Submit
             </b-button>
             <b-button
               v-ripple.400="'rgba(186, 191, 199, 0.15)'"
               type="button"
               variant="outline-secondary"
               @click="hide"
             >
               Cancel
             </b-button>
           </div>
         </b-form>
         
         
       </validation-observer>
   </template>
   </b-sidebar>
</template>
<script>
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile
 } from 'bootstrap-vue'
 import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { ref } from '@vue/composition-api'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import Ripple from 'vue-ripple-directive'
 import vSelect from 'vue-select'
 import countries from '@/@fake-db/data/other/countries'
 import store from '@/store'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'


import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
export default {
   components: {
     BSidebar,
     BForm,
     BFormGroup,
     BFormInput,
     BFormTextarea,
     BFormCheckbox,
     BFormInvalidFeedback,
     BButton,
     BTabs, BTab,
     BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,
     vSelect,
     quillEditor,
     // Form Validation
     ValidationProvider,
     ValidationObserver,
   },
   directives: {
     Ripple,
   },
   model: {
     prop: 'isNavigationFormSidebarActive',
     event: 'update:is-navigation-form-sidebar-active',
   },
   props: {
     isNavigationFormSidebarActive: {
       type: Boolean,
       required: true,
     },
    
     navigationData: {
       type: Object,
       required: false,
     },
   },
   data(){
      return {
        snowOption: {
          theme: 'snow',
        },
      }
   },
   setup(props, { emit }) {
    
       const toast = useToast()
       const tabIndex = ref(0);
       const featuredImage = ref({});
       const userStorage = JSON.parse(localStorage.getItem('userData'));
       const blankData = {
         
           icon:'',
           created_by:userStorage.id,
           created_at:null,
           title: '',
           uri:'',
           group_id:'',
           is_active:true,
           parent:0,
  
       }
       const Data = ref(JSON.parse(JSON.stringify(blankData)))
      
       const resetData = () => {
           Data.value = JSON.parse(JSON.stringify(blankData))
       }
   
       const getData = (data)=>{
        
            
           if(data.id){
                
               data.is_active = data.is_active?true:false;
               Data.value  = JSON.parse(JSON.stringify(data));
           }else{

            blankData.group_id = data.group_id;
            console.log('blankData',blankData);
            Data.value = JSON.parse(JSON.stringify(blankData))
           }
       }
       const onSubmit = () => {
        
           if(Data.value.id){
             
               store.dispatch('app-navigation/updateData', Data.value)
               .then((res) => {
                   
                   if(res.data.success){
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   emit('refetch-data')
                   emit('update:is-navigation-form-sidebar-active', false)
                   }else{
                   
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               
               })
           }else{
               store.dispatch('app-navigation/addData', Data.value)
               .then((res) => {
                   
                   if(res.data.success){
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   emit('refetch-data')
                   emit('update:is-navigation-form-sidebar-active', false)
                   }else{
                   
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               
               })
           }

       }

       
        const {
       refFormObserver,
       getValidationState,
       resetForm,
     } = formValidation(resetData)
     //upload
    
     return {
       Data,
       onSubmit,
      
       refFormObserver,
       getValidationState,
       resetForm,
       getData,
       tabIndex
      
       
     }
  
   }
   
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>