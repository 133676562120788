import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/navigations/v1/groups/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addGroup(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/navigations/v1/groups/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        updateGroup(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .put('/navigations/v1/groups/update/'+queryParams.id,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        deleteGroupData(ctx, id){
            return new Promise((resolve, reject) => {
                axios
                  .delete('/navigations/v1/groups/delete/'+id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })
        },
        updateOrdering(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .put('/navigations/v1/updateOrdering',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/navigations/v1/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        updateData(ctx,queryParams){
         
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .put('/navigations/v1/update/'+queryParams.id,data,{
                  headers:{
                    'Content-Type': 'multipart/form-data',
                    'dataType':'json'
                  }
                })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        deleteData(ctx,id){
          
            
          return new Promise((resolve, reject) => {
            axios
              .delete('/navigations/v1/delete/'+id)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })

      }
    }
}