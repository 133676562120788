import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useNavigationList() {
    const isGroupFormSidebarActive = ref(false);
    const isNavigationFormSidebarActive = ref(false);
    const groups = ref([]);
    const toast = useToast();
    const groupData = ref({});
    const navigationData = ref({});
    const updateGroup = (group)=>{
        isGroupFormSidebarActive.value = true;
        groupData.value = group;
    }
    const addGroup = ()=>{
        isGroupFormSidebarActive.value = true;
        groupData.value = {}
    }
    const updateNav = (nav)=>{
        isNavigationFormSidebarActive.value = true;
        navigationData.value = nav;
    }
    const addNav = (group_id)=>{
        isNavigationFormSidebarActive.value = true;
        navigationData.value = {
            group_id:group_id
        }
    }
    const refetchData = ()=>{
        store
        .dispatch('app-navigation/fetchData', {})
        .then(response => {
            
            const  data  = response.data.data
            groups.value =data;
            console.log('groups',groups.value);
            //const total = response.data.total?response.data.total:cats.length;
            //callback(cats)
    
        })
        .catch((error) => {
            console.log('error',error);
            toast({
            component: ToastificationContent,
            props: {
                title: "Error fetching Group' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })
        })
            
    }
    refetchData();
    
      const undateOrdering = (key)=>{
        let navs = groups.value[key].navigations;
         
        store.dispatch('app-navigation/updateOrdering',navs)
        
        
    }

    const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-navigation/deleteData',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Navigation",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
      const deleteGroupData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-navigation/deleteGroupData',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Group Navigation",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
      return {
        groups,
        groupData,
        isGroupFormSidebarActive,
        isNavigationFormSidebarActive,
        undateOrdering,
        updateGroup,
        addGroup,
        refetchData,
        addNav,
        updateNav,
        navigationData,
        deleteData,
        deleteGroupData
      }
    
}