<template>
    <b-sidebar
      id="group-form-sidebar"
      :visible="isGroupFormSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getData(groupData)"
      @hidden="resetForm(resetData)"
      @change="(val) => $emit('update:is-group-form-sidebar-active', val)"
    >
    <template #default="{ hide }"> 
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
                Navigation Group Form
            </h5>
    
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
        </div>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
          <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="Data.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Title"
                  @input="changeTitle"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Slug"
              rules="required"
            >
              <b-form-group
                label="Slug"
                label-for="slug"
              >
                <b-form-input
                  id="slug"
                  v-model="Data.slug"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Slug"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
    </template>

    </b-sidebar>
</template>
<script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref } from '@vue/composition-api'
  import { required, alphaNum, email, password,confirmed } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { useToast } from 'vue-toastification/composition'
  import { slugify } from '@/@fake-db/utils'
export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BFormInvalidFeedback,
      BButton,
      vSelect,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isGroupFormSidebarActive',
      event: 'update:is-group-form-sidebar-active',
    },
    props: {
      isGroupFormSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      groupData: {
        type: Object,
        required: false,
      },
    },
    
    setup(props, { emit }) {
        const toast = useToast()
       
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        const blankData = {
            created_by:userStorage.id,
            created_at:null,
            title: '',
            slug:'',
           
        }
        const Data = ref(JSON.parse(JSON.stringify(blankData)))
        const resetData = () => {
            Data.value = JSON.parse(JSON.stringify(blankData))
        }
        const getData = (data)=>{
           
            if(data.id){
         
               
                Data.value  = JSON.parse(JSON.stringify(data));
            }else{
              Data.value = JSON.parse(JSON.stringify(blankData))
            }
        }
       
        const onSubmit = () => {
            if(Data.value.id){
                
                store.dispatch('app-navigation/updateGroup', Data.value)
                .then((res) => {
                    
                    if(res.data.success){
                    toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: `Success`,
                        icon: 'BellIcon',
                        variant: 'success',
                        text: `${res.data.message}`,
                        },
                    });
                    emit('refetch-data')
                    emit('update:is-group-form-sidebar-active', false)
                    }else{
                    
                    toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: `Error`,
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: `${res.data.message}`,
                        },
                    });
                
                    
                    }
                
                })
            }else{
                console.log('Data',Data.value);
                store.dispatch('app-navigation/addGroup', Data.value)
                .then((res) => {
                    
                    if(res.data.success){
                    toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: `Success`,
                        icon: 'BellIcon',
                        variant: 'success',
                        text: `${res.data.message}`,
                        },
                    });
                    emit('refetch-data')
                    emit('update:is-group-form-sidebar-active', false)
                    }else{
                    
                    toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: `Error`,
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: `${res.data.message}`,
                        },
                    });
                
                    
                    }
                
                })
            }

        }
         const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetData)
      const changeTitle  = () => {
        Data.value.slug =  slugify(Data.value.title )
       }
      return {
        Data,
        onSubmit,
       
        refFormObserver,
        getValidationState,
        resetForm,
        resetData,
        getData,
        changeTitle
      }
   
    }
    
}
</script>